export default {
  data: () => ({
    newCalendrierElementIdentifiant: null
  }),
  methods: {
    async createOrDuplicateTableauBlancV2 () {
      /*
      How to create Tableau Blanc (does not work with duplication yet)
      1. Create Dossier, get the new Dossier Id
      2. Create Or Duplicate TableauBlancEnregistrement, using DossierId
      3. Create CalendrierElement, using DossierId
      4. Distribuer SallesUsagers to CalendrierElements
      5. If everythin passed, the operation is a success
      */

      // 1
      const dossierId = await this.createEmptyDossier()
      if (!dossierId) { return this.onCreationFailure() }

      // 2
      let enregistrementId = null
      const selectedId = this.selectedTableaux?.tableauxBlancs?.[0]?.id

      if ((this.$route?.query?.t && this.$route?.query?.titre) || selectedId) {
        enregistrementId = await this.duplicateTableauBlancEnregistrement(dossierId, selectedId)
      } else {
        enregistrementId = await this.createEmptyTableau(dossierId)
      }
      if (!enregistrementId) { return this.onCreationFailure() }

      // 3
      const calendrierElement = await this.createEmptyCalendrierElement(dossierId)
      if (!calendrierElement?.id || !calendrierElement?.batch) { return this.onCreationFailure() }

      // 4
      const created = await this.distribuerUsagersToCalendrierElement(calendrierElement.batch)
      if (!created) { return this.onCreationFailure() }

      // 5
      this.onCreationSuccess()
    },
    async createEmptyCalendrierElement (dossierId) {
      const attrs = {
        statut: 'ACTIF',
        titre: this.titre && this.titre !== '' ? this.titre : this.selectedTableaux.titre,
        description: this.description,
        debutAt: this.dateDebut ? new Date(this.dateDebut + 'T00:00:00').toJSON() : null,
        finAt: this.dateFin ? new Date(this.dateFin + 'T00:00:00').toJSON() : null,
        contenu: {
          id: dossierId,
          type: 'DOSSIER'
        }
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/devoirCalendrierElement.gql'),
          variables: {
            attrs: [attrs]
          }
        })
        if (!data.devoirCalendrierElement || data.devoirCalendrierElement.errors) {
          throw data.devoirCalendrierElement.errors ?? null
        }
        this.newCalendrierElementIdentifiant = data.devoirCalendrierElement.identifiant
        return data.devoirCalendrierElement
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async createEmptyDossier () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createSalleClasseEnregistrementDossier.gql'),
          variables: {
            attrs: {
              titre: this.titre,
              description: this.description
            }
          }
        })
        if (!data.createSalleClasseEnregistrementDossier || data.createSalleClasseEnregistrementDossier?.errors) {
          throw data.createSalleClasseEnregistrementDossier.errors ?? null
        }
        return data.createSalleClasseEnregistrementDossier.id
      } catch (e) {
        console.error('erreur creation dossier', e)
        return null
      }
    },
    async createEmptyTableau (dossierId) {
      const defaultEmptyTableauData = [{
        id: 1,
        dokoma_version: 1,
        data: '{}',
        gridProperties: {
          activeGrid: null,
          gridColor: '#000000',
          gridOpacity: 0.25,
          tableauBgColor: '#ffffff'
        }
      }]
      let dataToCopy = JSON.stringify(defaultEmptyTableauData)
      try {
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createSalleClasseEnregistrementTableauBlanc.gql'),
          variables: {
            dossier: dossierId,
            titre: this?.titre !== '' ? this.titre : this.selectedTableaux.titre,
            data: dataToCopy
          }
        })
        if (!data.createSalleClasseEnregistrementTableauBlanc || data.createSalleClasseEnregistrementTableauBlanc?.errors) {
          throw data.createSalleClasseEnregistrementTableauBlanc.errors ?? null
        }
        return data.createSalleClasseEnregistrementTableauBlanc.id
      } catch (e) {
        console.error('erreur creation tableau', e)
        return null
      }
    },
    async duplicateTableauBlancEnregistrement (dossierId, selectedId) {
      try {
        const itemSlug = this.$route?.query?.itemSlug
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/dupliquerSalleClasseEnregistrementTableauBlanc.gql'),
          variables: {
            dossier: dossierId,
            id: selectedId ?? this.$route?.query?.t,
            boutiqueItem: selectedId || itemSlug ? itemSlug : null
          }
        })
        if (!data.dupliquerSalleClasseEnregistrementTableauBlanc || data.dupliquerSalleClasseEnregistrementTableauBlanc?.errors) {
          throw data.dupliquerSalleClasseEnregistrementTableauBlanc?.errors ? data.dupliquerSalleClasseEnregistrementTableauBlanc.errors : null
        }
        return data.dupliquerSalleClasseEnregistrementTableauBlanc.id
      } catch (e) {
        console.error('erreur duplication tableau', e)
        return null
      }
    },
    async distribuerUsagersToCalendrierElement (calendrierElementBatch) {
      try {
        const sallesUsagers = this.selectedSallesUsager.map(ssu => ssu.id)
        const { data } = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/distribuerUsagerCalendrierElement.gql'),
          variables: {
            id: calendrierElementBatch,
            sallesUsagers
          }
        })
        return data.distribuerUsagerCalendrierElement
      } catch (e) {
        console.error('error while distributing SallesUsagers', e)
        return null
      }
    },
    onCreationSuccess () {
      this.creationLoading = false
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'success', titre: 'Succès', description: 'Le tableau blanc a été partagé aux élèves sélectionnés.' })
      this.$store.commit('App/setShowSnackbarGlobal', true)

      // Redirect to created tableau
      this.finish = true
      this.$router.push({
        name: 'tableauDetail___',
        params: {
          id: this.newCalendrierElementIdentifiant
        }
      })
    },
    onCreationFailure () {
      this.creationLoading = false
      this.showError = true
      this.$store.commit('App/setSnackbarGlobalContent', { type: 'error', titre: 'Erreur', description: "Une erreur est survenue lors de l'attribution d'élèves au tableau blanc." })
      this.$store.commit('App/setShowSnackbarGlobal', true)
    },
    async cloturerDevoir (id) {
      // id can be an id, identifiant or batch
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/archiverUsagerCalendrierElement.gql'),
          variables: { id }
        })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
}
